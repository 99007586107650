import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "900px",
};

const center = {
  lat: 49.263354,
  lng: -123.080381,
};

const dummyLocations = [
  {
    _id: "66d96fdc7216906f87ab13b0",
    name: "mcgregor Child Care Centre (0 - 3 Gdc)",
    image: null,
    city: "Vancouver",
    address: "1155 Broadway E",
    postalCode: "V5T 4V5",
    capacity: 2,
    description: "In-Home Care",
    lat: 49.263354,
    lng: -123.080381,
    licensed: false,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 6,
    createdAt: "2024-09-05T08:46:20.924Z",
    updatedAt: "2024-09-05T08:46:20.924Z",
    __v: 0,
  },
  {
    _id: "66d96fdb7216906f87ab13a6",
    name: "Mahon Park Childcare Centre",
    image: null,
    city: "North Vancouver",
    address: "1601 Forbes Ave",
    postalCode: "V7M 2Y4",
    capacity: 70,
    description: "Pre-School",
    lat: 49.323475,
    lng: -123.085086,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 40,
    createdAt: "2024-09-05T08:46:19.262Z",
    updatedAt: "2024-09-05T08:46:19.262Z",
    __v: 0,
  },
  {
    _id: "66d96fd47216906f87ab1374",
    name: "Bonnie Bairns Childcare Services",
    image: null,
    city: "North Vancouver",
    address: "2260 Philip Ave",
    postalCode: "V7P 2W8",
    capacity: 80,
    description: "Full-Day Care",
    lat: 49.330395,
    lng: -123.112745,
    licensed: true,
    ageGroups: ["66d96fd37216906f87ab136c"],
    dayCareTypes: ["66d96fd37216906f87ab136f", "66d96fd37216906f87ab1372"],
    clicks: 0,
    views: 5,
    createdAt: "2024-09-05T08:46:12.172Z",
    updatedAt: "2024-09-05T08:46:12.172Z",
    __v: 0,
  },
  {
    _id: "66d96fd57216906f87ab137f",
    name: "Willow Point Children's Centre",
    image: null,
    city: "Campbell River",
    address: "200 Larwood Rd",
    postalCode: "V9W 2S1",
    capacity: 40,
    description: "Pre-School",
    lat: 49.967369,
    lng: -125.21629,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 0,
    createdAt: "2024-09-05T08:46:13.634Z",
    updatedAt: "2024-09-05T08:46:13.634Z",
    __v: 0,
  },
  {
    _id: "66d96fd77216906f87ab138a",
    name: "Le' Lum' Uy' Lh",
    image: null,
    city: "Duncan",
    address: "5588 River Rd",
    postalCode: "V9L 6V9",
    capacity: 55,
    description: "Before and After Care",
    lat: 48.774361,
    lng: -123.707365,
    licensed: true,
    ageGroups: ["66d96fd67216906f87ab1382"],
    dayCareTypes: ["66d96fd67216906f87ab1385", "66d96fd67216906f87ab1388"],
    clicks: 0,
    views: 8,
    createdAt: "2024-09-05T08:46:15.104Z",
    updatedAt: "2024-09-05T08:46:15.104Z",
    __v: 0,
  },
  {
    _id: "66d96fd87216906f87ab1393",
    name: "Victoria Children's Centre",
    image: null,
    city: "Victoria",
    address: "1515 Blanshard St",
    postalCode: "V8W 3C8",
    capacity: 30,
    description: "Multi-Age Child Care",
    lat: 48.428183,
    lng: -123.361025,
    licensed: true,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd77216906f87ab1390", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 1,
    createdAt: "2024-09-05T08:46:16.356Z",
    updatedAt: "2024-09-05T08:46:16.356Z",
    __v: 0,
  },
  {
    _id: "66d96fd97216906f87ab139c",
    name: "Lonsdale Creek Day Care Centre  - Annex",
    image: null,
    city: "North Vancouver",
    address: "240 21St St W",
    postalCode: "V7M 1Z4",
    capacity: 2,
    description: "In-Home Care",
    lat: 49.328622,
    lng: -123.077103,
    licensed: false,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 1,
    createdAt: "2024-09-05T08:46:17.605Z",
    updatedAt: "2024-09-05T08:46:17.605Z",
    __v: 0,
  },
  {
    _id: "66d96fda7216906f87ab13a1",
    name: "Margaret Heights Day Care Centre",
    image: null,
    city: "North Vancouver",
    address: "21-1800 Rufus Drive",
    postalCode: "V7J 3L7",
    capacity: 60,
    description: "Full-Day Care",
    lat: 49.324439,
    lng: -123.04267,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 15,
    createdAt: "2024-09-05T08:46:18.434Z",
    updatedAt: "2024-09-05T08:46:18.434Z",
    __v: 0,
  },
  {
    _id: "66d96fdc7216906f87ab13ab",
    name: "Forest Grove Childcare Centre",
    image: null,
    city: "Burnaby",
    address: "36-8650 Cinnamon Dr",
    postalCode: "V5A 4H7",
    capacity: 2,
    description: "In-Home Care",
    lat: 49.264565,
    lng: -122.914866,
    licensed: false,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 22,
    createdAt: "2024-09-05T08:46:20.095Z",
    updatedAt: "2024-09-05T08:46:20.095Z",
    __v: 0,
  },
  {
    _id: "66d96fdd7216906f87ab13b5",
    name: "Eco Kids Care Inc.",
    image: null,
    city: "Abbotsford",
    address: "6256 Mt Lehman Rd",
    postalCode: "V4X 2G5",
    capacity: 50,
    description: "Pre-School�",
    lat: 49.115834,
    lng: -122.381674,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 18,
    createdAt: "2024-09-05T08:46:21.750Z",
    updatedAt: "2024-09-05T08:46:21.750Z",
    __v: 0,
  },
  {
    _id: "66d96fde7216906f87ab13ba",
    name: "Laugh And Learn Children Centre Branch",
    image: null,
    city: "Chilliwack",
    address: "5412 Vedder Rd",
    postalCode: "V2R 3M4",
    capacity: 100,
    description: "Full-Day Care",
    lat: 49.100184,
    lng: -121.962297,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 5,
    createdAt: "2024-09-05T08:46:22.578Z",
    updatedAt: "2024-09-05T08:46:22.578Z",
    __v: 0,
  },
  {
    _id: "66d96fdf7216906f87ab13bf",
    name: "Our Childrens Centre",
    image: null,
    city: "Victoria",
    address: "4001 Seymour Place",
    postalCode: "V8X 1W4",
    capacity: 2,
    description: "In-Home Care",
    lat: 48.452512,
    lng: -123.368938,
    licensed: false,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 2,
    createdAt: "2024-09-05T08:46:23.406Z",
    updatedAt: "2024-09-05T08:46:23.406Z",
    __v: 0,
  },
  {
    _id: "66d96fe07216906f87ab13c4",
    name: "Shannon Day Care",
    image: null,
    city: "Vancouver",
    address: "290-1200 73Rd Ave W",
    postalCode: "V6P 6G5",
    capacity: 120,
    description: "Full-Day Care",
    lat: 49.203994,
    lng: -123.133622,
    licensed: true,
    ageGroups: ["66d96fd37216906f87ab136c"],
    dayCareTypes: ["66d96fd37216906f87ab136f", "66d96fd37216906f87ab1372"],
    clicks: 0,
    views: 100,
    createdAt: "2024-09-05T08:46:24.234Z",
    updatedAt: "2024-09-05T08:46:24.234Z",
    __v: 0,
  },
  {
    _id: "66d96fe17216906f87ab13c9",
    name: "West Point Grey Under Three Day Care",
    image: null,
    city: "Vancouver",
    address: "1708 West 16Th Ave",
    postalCode: "V6J 2M1",
    capacity: 50,
    description: "Pre-School",
    lat: 49.256848,
    lng: -123.144396,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 80,
    createdAt: "2024-09-05T08:46:25.062Z",
    updatedAt: "2024-09-05T08:46:25.062Z",
    __v: 0,
  },
  {
    _id: "66d96fe17216906f87ab13ce",
    name: "Whistler Children's Centre",
    image: null,
    city: "Whistler",
    address: "7146 Nesters Rd",
    postalCode: "V0N 1B7",
    capacity: 80,
    description: "Full-Day Care",
    lat: 50.12399,
    lng: -122.960699,
    licensed: true,
    ageGroups: ["66d96fd37216906f87ab136c"],
    dayCareTypes: ["66d96fd37216906f87ab136f", "66d96fd37216906f87ab1372"],
    clicks: 0,
    views: 250,
    createdAt: "2024-09-05T08:46:25.888Z",
    updatedAt: "2024-09-05T08:46:25.888Z",
    __v: 0,
  },
  {
    _id: "66d96fe27216906f87ab13d3",
    name: "Castleview Child Care Centre",
    image: null,
    city: "Victoria",
    address: "1075 Joan Cres",
    postalCode: "V8S 3L3",
    capacity: 2,
    description: "In-Home Care",
    lat: 48.423331,
    lng: -123.343216,
    licensed: false,
    ageGroups: ["66d96fd77216906f87ab138d"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 18,
    createdAt: "2024-09-05T08:46:26.715Z",
    updatedAt: "2024-09-05T08:46:26.715Z",
    __v: 0,
  },
  {
    _id: "66d96fe37216906f87ab13d8",
    name: "Camosun College Child Care Services - Interurban",
    image: null,
    city: "Victoria",
    address: "4461 Interurban Rd",
    postalCode: "V9E 2C1",
    capacity: 2,
    description: "In-Home Care",
    lat: 48.491032,
    lng: -123.416365,
    licensed: false,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd87216906f87ab1397", "66d96fd97216906f87ab139a"],
    clicks: 0,
    views: 6,
    createdAt: "2024-09-05T08:46:27.540Z",
    updatedAt: "2024-09-05T08:46:27.540Z",
    __v: 0,
  },
  {
    _id: "66d96fe47216906f87ab13dd",
    name: "Camosun College Child Care Services - Lansdowne",
    image: null,
    city: "Victoria",
    address: "3211 Richmond Rd",
    postalCode: "V8P 4P2",
    capacity: 75,
    description: "Pre-School�",
    lat: 48.448484,
    lng: -123.325345,
    licensed: true,
    ageGroups: ["66d96fd47216906f87ab1377"],
    dayCareTypes: ["66d96fd57216906f87ab137a", "66d96fd57216906f87ab137d"],
    clicks: 0,
    views: 140,
    createdAt: "2024-09-05T08:46:28.367Z",
    updatedAt: "2024-09-05T08:46:28.367Z",
    __v: 0,
  },
];

const App = () => {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (map && dummyLocations.length) {
      dummyLocations.forEach((location) => {
        console.log(location.lat, location.lng);
        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng }, // Use lng here
          map,
          title: location.name,
        });

        marker.addListener("click", () => {
          setSelectedMarker(location);
        });
      });
    }
  }, [map]);

  return (
    <div>
      <h1>Map Demo</h1>
      <LoadScript
        googleMapsApiKey="AIzaSyBOQcjeFArro1BOOAl5vYTEWaB2eSigMfk"
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          onLoad={(map) => setMap(map)}
        >
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }} // Use lng here
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <b>{selectedMarker.name}</b>
                <p>{selectedMarker.description}</p>
                <p>
                  {selectedMarker.city}, {selectedMarker.postalCode}
                </p>
                <p>Capacity: {selectedMarker.capacity}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default App;
