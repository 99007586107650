import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  LoadScript,
} from "@react-google-maps/api";
import { imgUrl } from "../../../utilities/config";
import Rectangle from "../../../assets/images/rectangle.png";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Button,
  Image,
  Stack,
  Img,
  Heading,
} from "@chakra-ui/react";
import { ArrowRightIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import { PUT } from "../../../utilities/ApiProvider";
const containerStyle = {
  height: "100vh",
  width: "100%",
};

const DayCareMap = ({ data }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBOQcjeFArro1BOOAl5vYTEWaB2eSigMfk",
  });

  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [center, setCenter] = useState({ lat: 49.330395, lng: -123.112745 });

  const containerStyle = {
    width: "100%",
    height: "90vh",
    borderRadius: "12px",
  };
  const handleClickAndViews = async (id, _type) => {
    try {
      const response = await PUT(`/daycare/daycare-activity/${id}`, {
        type: _type,
      });
      console.log("API Response:", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchMarkers = async () => {
      setTimeout(() => {
        if (data.length > 0) {
          setMarkers(data);

          const firstMarker = data[0];
          setCenter({ lat: firstMarker.lat, lng: firstMarker.long });
        }
      }, 1000);
    };

    fetchMarkers();
  }, [data]);

  const memoizedMarkers = useMemo(() => markers, [markers]);

  const handleMarkerClick = useCallback((marker) => {
    setSelectedMarker(marker);
    handleClickAndViews(marker?._id, "view");
  }, []);
  const redirectToGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.location.href = url;
    window.open(url, "_blank");
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedMarker(null);
  };
  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <Stack>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={() => {
          console.log("Map loaded, markers ready to render.");
        }}
      >
        {memoizedMarkers.map((marker) => (
          <Marker
            key={marker.id}
            position={{ lat: marker.lat, lng: marker.long }}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}

        {/* Display InfoWindow when a marker is selected */}
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.long }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <Stack gap={2} width={"200px"}>
              <Img
                width={"200px"}
                borderRadius={8}
                src={
                  selectedMarker?.image
                    ? `${imgUrl}${selectedMarker.image}`
                    : `${Rectangle}`
                }
                alt={selectedMarker.name}
              />
              <Stack gap={1}>
                <Heading fontSize={"14px"}>{selectedMarker.name}</Heading>
                <Text>{selectedMarker.details}</Text>
                <Text fontSize={"12px"} fontWeight={500} color={"#111"}>
                  <strong>Address:</strong> {selectedMarker.address}
                </Text>
              </Stack>
              <Stack>
                <Button
                  margin={{ base: "auto", md: "0", xl: "0" }}
                  fontSize={"12px"}
                  fontWeight={400}
                  color={"white"}
                  letterSpacing={1}
                  fontFamily={"Poppins"}
                  padding={"14px 40px"}
                  bg={"#784FCF"}
                  _hover={{
                    bg: "#784FCF",
                  }}
                  onClick={() => {
                    setIsDrawerOpen(true);
                    handleClickAndViews(selectedMarker?._id, "click");
                  }}
                >
                  View Details
                </Button>
              </Stack>
            </Stack>
          </InfoWindow>
        )}
        {isDrawerOpen && (
          <Box
            position="absolute"
            top="0"
            height={"100%"}
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="end"
            alignItems="end"
            zIndex="10"
          >
            <Stack
              width="400px"
              height="100%"
              backgroundColor="white"
              dropShadow={"1px"}
              p={4}
              boxShadow="lg"
              position="relative"
              gap={4}
            >
              <Box
                onClick={() => setIsDrawerOpen(false)}
                cursor={"pointer"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"50%"}
                bg={"#803CCF"}
                width={"30px"}
                height={"30px"}
              >
                <CloseIcon color={"#fff"} fontSize={"14px"} />
              </Box>

              <Image
                src={
                  selectedMarker?.image
                    ? `${imgUrl}${selectedMarker.image}`
                    : `${Rectangle}`
                }
                alt={selectedMarker.name}
                borderRadius="md"
                mb={4}
              />
              <Text fontSize="lg" fontWeight="bold">
                {selectedMarker.name}
              </Text>
              <Text fontSize="md" color="gray.600">
                {selectedMarker.address}
              </Text>
              <Text>{selectedMarker.description}</Text>
              <Button
                onClick={() =>
                  redirectToGoogleMaps(selectedMarker.lat, selectedMarker.long)
                }
                colorScheme="purple"
              >
                View Location
              </Button>
            </Stack>
          </Box>
        )}
      </GoogleMap>
    </Stack>
  );
};

export default React.memo(DayCareMap);
